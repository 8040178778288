<template>
  <div class="sites-container" v-if="dataLoaded">
    <a-row style="marginBottom: 20px;">
      <a-row>
        <div class="table-gadgets">
          <a-col v-if="isSiteManager" :span="2" :offset="2">
            <a-button
              :disabled="disableAddSite"
              class="btn btn-primary"
              type="primary"
              @click="addSite()"
            >
              {{ $t("form.add") }}
            </a-button>
          </a-col>
          <a-col :span="8" :offset="isSiteManager ? 10 : 2">
            <a-input-search
              class="input-search-table"
              placeholder="Chercher un site..."
              v-model="searchSite"
            />
          </a-col>
        </div>
      </a-row>
      <a-row type="flex" style="marginTop: 20px;">
        <a-col :span="20" :offset="2">
          <a-table
            class="siteList-table"
            :columns="columns"
            :dataSource="resultSiteSearch"
            rowKey="id"
            :pagination="pagination"
            @change="onTableChange"
          >
            <template
              v-for="col in ['name', 'companyName', 'companyAddress']"
              :slot="col"
              slot-scope="text, record, index"
            >
              <div :key="col">
                <a-form-item
                  v-if="editingSite && record.id === editingSite.id"
                  :validate-status="
                    invalidKeys.indexOf(col) === -1 ? '' : 'error'
                  "
                >
                  <a-input
                    style="margin: -5px 0"
                    :value="editingSite[col]"
                    @change="(e) => handleChange(e.target.value, index, col)"
                    v-on:keyup.enter="() => save(editingSite)"
                    :ref="col"
                  />
                </a-form-item>
                <template v-else>{{ text }}</template>
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <span v-if="editingSite && record.id === editingSite.id">
                <a-row class="btn-form">
                  <a-col :span="12">
                    <a-button
                      @click="() => save(editingSite)"
                      type="link"
                      class="btn btn-editing"
                      :title="$t('form.save')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="check-circle"
                      />
                    </a-button>
                  </a-col>
                  <a-col :span="12">
                    <a-button
                      @click="() => cancel()"
                      type="link"
                      class="btn btn-cancel"
                      :title="$t('form.cancel')"
                    >
                      <a-icon
                        class="btn-icon editing-form"
                        type="close-circle"
                      />
                    </a-button>
                  </a-col>
                </a-row>
              </span>
              <span v-if="editingSite === null">
                <a-dropdown>
                  <a-menu slot="overlay">
                    <a-menu-item key="edit" @click="edit(record)">
                      <a-icon type="edit" />{{ $t("form.edit") }}
                    </a-menu-item>
                    <a-menu-item key="delete" @click="confirmDelete(record)">
                      <a-icon type="delete" />{{
                        $t("form.delete")
                      }}</a-menu-item
                    >
                  </a-menu>
                  <a-button style="margin-left: 8px">
                    <a-icon type="more" />
                  </a-button>
                </a-dropdown>
              </span>
            </template>
          </a-table>
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Modal,
  Form,
  Input,
} from "ant-design-vue";
import { isSiteManager } from "../../utils/userAuth";

Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(Button);
Vue.use(Form);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(Modal);

export default {
  name: "sites-manager",
  computed: {
    dataLoaded() {
      return this.$store.state.sites.lastFetch !== null;
    },
    isSiteManager() {
      return isSiteManager();
    },
    disableAddSite() {
      return this.editingSite !== null;
    },
    columns() {
      const columns = [
        {
          title: this.$t("common.name"),
          dataIndex: "name",
          key: "name",
          width: "15%",
          scopedSlots: { customRender: "name" },
        },
        {
          title: this.$t("sites.companyName"),
          dataIndex: "companyName",
          key: "companyName",
          width: "15%",
          scopedSlots: { customRender: "companyName" },
        },
        {
          title: this.$t("sites.companyAddress"),
          dataIndex: "companyAddress",
          key: "companyAddress",
          width: "15%",
          scopedSlots: { customRender: "companyAddress" },
        },
      ];
      if (this.isSiteManager) {
        columns.push({
          dataIndex: "action",
          width: "5%",
          scopedSlots: { customRender: "action" },
        });
      }
      return columns;
    },
    resultSiteSearch() {
      if (this.searchSite) {
        return this.sites.filter((item) => {
          return this.searchSite
            .toLowerCase()
            .split("")
            .every(
              (v) =>
                item.name.toLowerCase().includes(v) ||
                item.companyName.toLowerCase().includes(v) ||
                item.companyAddress.toLowerCase().includes(v)
            );
        });
      }
      return this.sites;
    },
  },
  mounted() {
    this.$store.dispatch("fetchSites").then(() => {
      this.refreshSitesFromStore();
    });
  },
  data() {
    return {
      editingSite: null,
      pagination: {
        defaultCurrent: 1,
        current: 1,
      },
      sites: [],
      searchSite: null,
      invalidKeys: [],
    };
  },
  methods: {
    onTableChange(pagination) {
      this.pagination.current = pagination.current;
    },
    refreshSitesFromStore() {
      this.sites = [...this.$store.state.sites.sites];
    },
    addSite() {
      this.pagination.current = 1;
      
      const newSite = {
        id: "new",
        name: "",
        companyName: "",
        companyAddress: "",
        bases: [],
      };
      this.sites.unshift(newSite);
      this.editingSite = newSite;
    },
    handleChange(value, key, column) {
      if (this.editingSite) {
        this.editingSite[column] = value;
      }
    },
    cancel() {
      let isNew = this.editingSite.id === "new";

      if (isNew) {
        this.sites.shift();
      }

      this.editingSite = null;
    },
    validateSite(site) {
      let invalidKeys = [];
      if (site.name === "") {
        invalidKeys.push("name");
      }
      if (site.companyName === "") {
        invalidKeys.push("companyName");
      }
      if (site.companyAddress === "") {
        invalidKeys.push("companyAddress");
      }

      this.invalidKeys = invalidKeys;
      return invalidKeys.length === 0;
    },
    save(target) {
      if (Object.hasOwnProperty.call(target, "id")) {
        if (this.validateSite(target)) {
          let isNew = this.editingSite.id === "new";
          let promise = null;

          if (isNew) {
            promise = this.$store.dispatch("addSite", target);
          } else {
            promise = this.$store.dispatch("updateSite", target);
          }

          promise.then(() => {
            this.editingSite = null;
            this.refreshSitesFromStore();
          });
        }
      }
    },
    edit(target) {
      this.editingSite = { ...target };
    },
    confirmDelete(site) {
      Modal.confirm({
        title: this.$t("form.deleteConfirm"),
        onOk: () => {
          this.$store.dispatch("deleteSite", site).then(() => {
            if (site.id === this.$store.state.sites.currentSiteId) {
              this.$store.dispatch("setCurrentSiteId", null);
            }

            this.refreshSitesFromStore();
          });
        },
        onCancel: () => {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
 .btn-form {
    height: 2.5rem;
    width: 3.75rem;
    font-size: 1.5rem;
  }

  .btn-editing{
    margin-bottom: 1.1rem;
    font-size: 1.5rem;
    color: #f0de00
  }

  .btn-cancel {
    margin-bottom: 1.1rem;
    font-size: 1.5rem;
    color: red;
  }
</style>
